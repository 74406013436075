var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-cy":"924","fluid":""}},[_c('v-row',{staticClass:"mb-md-4",attrs:{"data-cy":"906"}},[_c('v-col',{staticClass:"col-md-4 col-12",attrs:{"data-cy":"907"}},[_c('v-combobox',{ref:"input_search",attrs:{"data-cy":"908","label":_vm.$t('123'),"items":_vm.itemsForSearch,"append-icon":"mdi-magnify","outlined":"","single-line":"","hide-details":"","multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1),_c('v-col',{staticClass:"col-md-8 col-12 align-self-end",attrs:{"data-cy":"909"}},[_c('v-tooltip',{ref:"tooltip_for_disabled_add_user",attrs:{"data-cy":"1010","max-width":"1000","color":"grey darken-2","nudge-left":"110","disabled":!_vm.isAddUserButtonDisabled,"left":"","offset-overflow":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({ref:"d_block_add_user",staticClass:"d-block float-right",attrs:{"data-cy":"1222"}},on),[_c('v-btn',{ref:"button_add_user",attrs:{"data-cy":"910","color":"primary","block":_vm.$vuetify.breakpoint.smAndDown,"to":{ name: 'user-add' },"disabled":_vm.isAddUserButtonDisabled}},[_c('v-icon',{attrs:{"data-cy":"911","left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("124")))],1)],1)]}}])},[_c('span',{ref:"tooltip_for_disabled_add_user",attrs:{"data-cy":"1011"}},[_vm._v(_vm._s(_vm.$t("1417")))])])],1)],1),_c('v-data-table',{ref:"table_users",staticClass:"elevation-1",attrs:{"data-cy":"912","item-key":"id","items":_vm.usersForTable,"search":_vm.search,"headers":_vm.headersForTable,"loading":_vm.isLoadingUsers,"loading-text":_vm.$t(_vm.$TABLES.USERS.loadingText),"no-data-text":_vm.$t(_vm.$TABLES.USERS.noDataText),"no-results-text":_vm.$t(_vm.$TABLES.USERS.noResultText, {searchQuery: _vm.search}),"footer-props":_vm.footersForTable,"items-per-page":_vm.$TABLES.USERS.itemsPerPage,"custom-filter":_vm.customFilterForTable,"fixed-header":"","multi-sort":""},on:{"click:row":function (item) { return _vm.$router.push({ name: 'user', params: { id: item.id } }); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref ){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",attrs:{"data-cy":"913"}},[_c('div',{staticClass:"d-flex mr-2",attrs:{"data-cy":"914"}},[_c('v-avatar',{attrs:{"data-cy":"915","color":"info lighten-4","size":"38"}},[_c('span',{staticClass:"secondary--text text--darken-2",attrs:{"data-cy":"916"}},[_vm._v(_vm._s(_vm._f("initials")(item.name)))])])],1),_c('div',{staticClass:"d-block ml-2 caption",attrs:{"data-cy":"917"}},[_c('div',{staticClass:"d-block",attrs:{"data-cy":"918"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"d-block",attrs:{"data-cy":"919"}},[_vm._v(_vm._s(item.jobTitle))])])])]}},{key:"item.status",fn:function(ref ){
var item = ref.item;
return [_c('span',{class:[item.status === '131' ? 'info--text font-italic' : 'success--text'],attrs:{"data-cy":"920"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.mfaStatus",fn:function(ref ){
var item = ref.item;
return [_c('span',{staticClass:"success--text",attrs:{"data-cy":"920"}},[_vm._v(_vm._s(item.mfaStatus))])]}},{key:"item.groups",fn:function(ref ){
var item = ref.item;
return _vm._l((item.groups),function(group,index){return _c('div',{staticClass:"d-inline-flex",attrs:{"data-cy":("921_" + index)}},[(index < 2)?_c('v-chip',{staticClass:"primary--text text--lighten-1 mr-2",attrs:{"data-cy":"922","label":""}},[_vm._v(_vm._s(group))]):_vm._e(),(index == 2)?_c('span',{staticClass:"text--secondary mx-1",attrs:{"data-cy":"923"}},[_vm._v("(+"+_vm._s(item.groups.length - 2)+" others)")]):_vm._e()],1)})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }